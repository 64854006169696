import { clsx } from 'clsx';
import React from 'react';

import styles from './Button.module.css';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  text?: string;
  variant?: 'primary' | 'secondary' | 'additional' | 'text';
  icon?: React.ReactNode;
  rounded?: boolean;
  overrideFillIcon?: boolean;
};

export const Button = ({
  children,
  text,
  variant = 'primary',
  icon,
  rounded,
  overrideFillIcon,
  ...buttonProps
}: ButtonProps) => {
  const haveContent = Boolean(text || children);

  const className = clsx(styles.button, {
    [styles.overrideFillIcon]: overrideFillIcon,
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.additional]: variant === 'additional',
    [styles.invisible]: variant === 'text',
    [styles.onlyIcon]: !haveContent,
    [styles.rounded]: rounded,
  });

  return (
    <button className={className} {...buttonProps}>
      {text || children}
      {icon && (
        <span className={clsx({ [styles.iconMarginLeft]: haveContent })}>
          {icon}
        </span>
      )}
    </button>
  );
};
