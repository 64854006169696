import { IconName } from '../components/uikit/Icon/Icon';

export enum ServiceCode {
  nsi = 'nsi',
  dsp = 'dsp',
  pln = 'pln',
  adm = 'adm',
}

export enum GroupCode {
  MainNSI = 'main-nsi',
  TechnologicalNSI = 'technological-nsi',
  Planning = 'planning',
  Administrator = 'administrator',
}

export enum SubGroupCode {
  Relationships = 'relationships',
  TypesOfWork = 'types-of-work',
  Nomenclature = 'nomenclature',
  Equipment = 'equipment',
  Personnel = 'personnel',
  ProductionStructure = 'production-structure',
  OtherReferenceBooks = 'other-reference-books',
  TechnologicalCompositions = 'technological-compositions',
  TechnologicalProcesses = 'technological-processes',
  Orders = 'orders',
}

export type FormCode = string;

export interface IFormGroup {
  code: GroupCode;
  name: string;
  iconName: IconName;
}

export interface IFormSubGroup {
  code: SubGroupCode;
  name: string;
}

export interface IForm {
  code: FormCode;
  api?: string; // если нет берем code
  urlParams?: string;
  name: string;
  groupCode: GroupCode;
  subGroupCode?: SubGroupCode;
  serviceCode: ServiceCode;
}

// prettier-ignore
export const GroupsList: IFormGroup[] = [
  { code: GroupCode.MainNSI, name: 'Основная НСИ', iconName: 'Note' },
  { code: GroupCode.TechnologicalNSI, name: 'Технологическая НСИ', iconName: 'List' },
  { code: GroupCode.Planning, name: 'Планирование', iconName: 'CalendarClock' },
  { code: GroupCode.Administrator, name: 'Администратор', iconName: 'Settings' },
]

// prettier-ignore
export const SubGroupsList: IFormSubGroup[] = [
  { code: SubGroupCode.Relationships, name: 'Взаимоотношения' },
  { code: SubGroupCode.TypesOfWork, name: 'Виды работ' },
  { code: SubGroupCode.Nomenclature, name: 'Номенклатура' },
  { code: SubGroupCode.Equipment, name: 'Оборудование' },
  { code: SubGroupCode.Personnel, name: 'Персонал' },
  { code: SubGroupCode.ProductionStructure, name: 'Структура производства' },
  { code: SubGroupCode.OtherReferenceBooks, name: 'Другие справочники' },
  { code: SubGroupCode.TechnologicalCompositions, name: 'Технологические составы' },
  { code: SubGroupCode.TechnologicalProcesses, name: 'Техпроцессы' },
  { code: SubGroupCode.Orders, name: 'Заказы' },
]

// prettier-ignore
export const FormsList: IForm[] = [
  // Основная НСИ
  // Взаимоотношения
  { name: 'Валюты', code: 'currency', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Relationships, serviceCode: ServiceCode.nsi },
  { name: 'Группы контрагентов', code: 'counterparty_group', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Relationships, serviceCode: ServiceCode.nsi },
  { name: 'Договоры', code: 'contract', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Relationships, serviceCode: ServiceCode.nsi },
  { name: 'Контрагенты', code: 'counterparty', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Relationships, serviceCode: ServiceCode.nsi },
  // Виды работ
  { name: 'Виды документов допуска', code: 'allow_doc_type', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.TypesOfWork, serviceCode: ServiceCode.nsi },
  { name: 'Виды работ', code: 'labour_type', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.TypesOfWork, serviceCode: ServiceCode.nsi },
  { name: 'Причины простоев', code: 'downtime_reason', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.TypesOfWork, serviceCode: ServiceCode.nsi },
  { name: 'Технологические операции', code: 'processing_operation', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.TypesOfWork, serviceCode: ServiceCode.nsi },
  // Номенклатура
  { name: 'Виды профиля', code: 'profile_kind', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Группы ЕИ', code: 'unit_group', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Группы номенклатуры', code: 'nomenclature_group', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Единицы измерения', code: 'unit', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Замены номенклатуры', code: 'replacements', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Категории номенклатуры', code: 'nom_category', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Марки материала', code: 'material_grade', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Номенклатура', code: 'nomenclature', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Профили материала', code: 'material_profile', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  { name: 'Серийные номера', code: 'serial_number', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Nomenclature, serviceCode: ServiceCode.nsi },
  // Оборудование
  { name: 'Виды оборудования', code: 'equipment_type', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Equipment, serviceCode: ServiceCode.nsi },
  { name: 'Группы оборудования', code: 'equipment_group', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Equipment, serviceCode: ServiceCode.nsi },
  { name: 'Единицы оборудования', code: 'equipment', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Equipment, serviceCode: ServiceCode.nsi },
  // Персонал
  { name: 'Виды тарифов', code: 'rate_kind', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Personnel, serviceCode: ServiceCode.nsi },
  { name: 'МОЛ', code: 'flp', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Personnel, serviceCode: ServiceCode.nsi },
  { name: 'Причины отсутствия', code: 'absence_reason', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Personnel, serviceCode: ServiceCode.nsi },
  { name: 'Профессии', code: 'profession', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Personnel, serviceCode: ServiceCode.nsi },
  { name: 'Сотрудники', code: 'employee', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Personnel, serviceCode: ServiceCode.nsi },
  { name: 'Условия труда', code: 'working_conditions', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.Personnel, serviceCode: ServiceCode.nsi },
  // Структура производства
  { name: 'Графики работы', code: 'schedule', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.ProductionStructure, serviceCode: ServiceCode.nsi },
  { name: 'Складские ячейки', code: 'sell_units', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.ProductionStructure, serviceCode: ServiceCode.nsi },
  { name: 'Структура производства', code: 'production_unit', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.ProductionStructure, serviceCode: ServiceCode.nsi },
  // Другие справочники
  { name: 'Виды брака', code: 'scrap_reason', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.OtherReferenceBooks, serviceCode: ServiceCode.nsi },
  { name: 'Причины изменений', code: 'change_reason', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.OtherReferenceBooks, serviceCode: ServiceCode.nsi },
  { name: 'Причины несоответствия', code: 'non_comp_reason', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.OtherReferenceBooks, serviceCode: ServiceCode.nsi },
  { name: 'Указания о заделе', code: 'assignment_doc', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.OtherReferenceBooks, serviceCode: ServiceCode.nsi },
  { name: 'Шифры затрат', code: 'cost_code', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.OtherReferenceBooks, serviceCode: ServiceCode.nsi },
  { name: 'Контексты', code: 'dict_context', groupCode: GroupCode.MainNSI, subGroupCode: SubGroupCode.OtherReferenceBooks, serviceCode: ServiceCode.nsi },
  // Технологическая НСИ
  // Технологические составы
  { name: 'Технологические составы', code: 'tech_revision', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.TechnologicalCompositions, serviceCode: ServiceCode.nsi },
  // Техпроцессы
  { name: 'Рабочие техпроцессы', code: 'work_techprocess', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.TechnologicalProcesses, serviceCode: ServiceCode.nsi },
  { name: 'Типовые техпроцессы', code: 'type_techproc', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.TechnologicalProcesses, serviceCode: ServiceCode.nsi },
  { name: 'Шаблоны техпроцесса', code: 'techprocess_template', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.TechnologicalProcesses, serviceCode: ServiceCode.nsi },
  // Заказы
  { name: 'Заказы на ГП', code: 'order_fp', api: 'order_fp', urlParams: 'direction=1', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.Orders, serviceCode: ServiceCode.nsi },
  { name: 'Внутренний ГП', code: 'order_fp_inner', api: 'order_fp', urlParams: 'direction=2', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.Orders, serviceCode: ServiceCode.nsi },
  { name: 'Кооперация', code: 'order_fp_coop', api: 'order_fp', urlParams: 'direction=3', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.Orders, serviceCode: ServiceCode.nsi },
  { name: 'Заказы-Потребности', code: 'order_fpp', groupCode: GroupCode.TechnologicalNSI, subGroupCode: SubGroupCode.Orders, serviceCode: ServiceCode.nsi },
  // Технологическая НСИ
  // Технологические составы
  { name: 'Пользователи', code: 'users', groupCode: GroupCode.Administrator, serviceCode: ServiceCode.adm },
  { name: 'Роли пользователей', code: 'roles', groupCode: GroupCode.Administrator, serviceCode: ServiceCode.adm },

]

export interface ListItemT {
  name: string;
  path: string;
}

export interface MenuSubgroupT {
  code: string;
  name: string;
  path?: string;
  lists: ListItemT[];
}

export interface MenuGroupT {
  code: string;
  name: string;
  path: string;
  iconName: IconName;
  lists?: ListItemT[];
  subgroups?: MenuSubgroupT[];
}

function transformData(
  groups: IFormGroup[],
  subgroups: IFormSubGroup[],
  forms: IForm[]
): MenuGroupT[] {
  return groups.map((group) => {
    const groupSubgroups = subgroups
      .filter((subgroup) =>
        forms.some(
          (form) =>
            form.groupCode === group.code && form.subGroupCode === subgroup.code
        )
      )
      .map((subgroup) => {
        const subgroupForms = forms
          .filter(
            (form) =>
              form.groupCode === group.code &&
              form.subGroupCode === subgroup.code
          )
          .map((form) => ({
            path: `/forms/${group.code}/${subgroup.code}/${form.code}`,
            name: form.name,
          }));

        return {
          code: subgroup.code,
          name: subgroup.name,
          path: `/forms/${group.code}/${subgroup.code}`,
          lists: subgroupForms,
        };
      });


    const listForms = forms
      .filter(
        (form) =>
          form.groupCode === group.code
      )
      .map((form) => ({
        path: `/forms/${group.code}/${form.code}`,
        name: form.name,
      }));

    const children = groupSubgroups.length ? { subgroups: groupSubgroups} : { lists: listForms}

    return {
      code: group.code,
      name: group.name,
      path: `/forms/${group.code}`,
      iconName: group.iconName,
      ...children
    };
  });
}

export const getListMenuArray = (filter: string = '') => {
  return transformData(
    GroupsList,
    SubGroupsList,
    FormsList.filter(
      (form) =>
        filter === '' || form.name.toLowerCase().includes(filter.toLowerCase())
    )
  );
};
