import { useParams } from 'react-router-dom';
import { ListForm } from '../components/forms/ListForm/ListForm';
import { FormsList } from '../models/list-forms-model';

export const ListFormPage = () => {

  const { formCode } = useParams();

  const form = FormsList.find((form) => form.code === formCode);

  if (!form) return null;

  return (
    <ListForm form={form} />
  );
}
