import { produce } from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { IForm } from '../../../models/list-forms-model';
import { MetadataContext } from '../../../propviders/MetadataPropvider';
import { apiGetNsi } from '../../../servises/nsi';
import {
  ListFormData,
  SchemaField,
  // SetFieldProp,
} from '../../../types/common/list-form';
import {
  formFieldStorage,
  getFormFieldStorageKey,
} from '../../../utils/localStorage';
import { Button } from '../../uikit/Button/Button';
import { Icon } from '../../uikit/Icon/Icon';
import { getSchema, validateStorage } from '../helper';
import { ListFormTable } from '../ListFormTable/ListFormTable';

type ListFormProps = {
  form: IForm;
};

export const ListForm = ({ form }: ListFormProps) => {
  const { serviceCode: service, code, api, urlParams, name: formName } = form;

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<
    ListFormData & { service: string; code: string }
  >({ rows: [], fields: [], service, code });

  const { isLoadingMetadata, metaFields } = useContext(MetadataContext);

  // save fields to localStorage
  useEffect(() => {
    if (formData.fields.length) {
      formFieldStorage.add(
        getFormFieldStorageKey(formData.service, formData.code),
        formData.fields
      );
    }
  }, [formData]);

  useEffect(() => {
    setIsLoading(true);
    apiGetNsi(service, api || code, urlParams)
      .then((rows) => {
        let fields: SchemaField[] = getSchema(rows, metaFields);

        const storageFields = formFieldStorage.get(
          getFormFieldStorageKey(service, code)
        );

        if (
          storageFields &&
          storageFields.length &&
          validateStorage(fields, storageFields)
        ) {
          fields = storageFields;
        } else {
          formFieldStorage.add(getFormFieldStorageKey(service, code), fields);
        }

        setFormData({
          service,
          code: code,
          fields,
          rows: rows.map((row, index) => ({
            ...row,
            _key: index,
            _selected: false,
          })),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [api, code, service, urlParams]);

  if (isLoading || isLoadingMetadata) return <>Loading...</>;

  const selectRow = (key: number, value: boolean) => {
    setFormData((_form) =>
      produce(_form, (draft) => {
        const rowIndex = draft.rows.findIndex(({ _key }) => _key === key);
        draft.rows[rowIndex]._selected = value;
      })
    );
  };

  // const setFieldProp: SetFieldProp = (code, propName, value) => {
  //   setForm((_form) =>
  //     produce(_form, (draft) => {
  //       const fieldIndex = draft.fields.findIndex(
  //         (field) => field.code === code
  //       );
  //       draft.fields[fieldIndex][propName] = value;
  //     })
  //   );
  // };

  const setSortField = (code: string) => {
    setFormData((_form) =>
      produce(_form, (draft) => {
        const fieldIndex = draft.fields.findIndex(
          (field) => field.code === code
        );
        const currentSort = draft.fields[fieldIndex].sort;
        // сбрасываем сортировки
        for (const field of draft.fields) {
          field.sort = null;
        }
        switch (currentSort) {
          case 'ASC': {
            draft.fields[fieldIndex].sort = 'DESC';
            return;
          }
          case 'DESC': {
            draft.fields[fieldIndex].sort = null;
            return;
          }
          default: {
            draft.fields[fieldIndex].sort = 'ASC';
            return;
          }
        }
      })
    );
  };

  const selectedRows = formData.rows.filter(({ _selected }) => _selected);

  return (
    <div style={{ padding: 20, height: 'calc(100% - 40px)'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10,
          height: 56,
        }}
      >
        <h2
          style={{
            fontSize: 18,
            lineHeight: '26px',
          }}
        >
          {formName}
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Button variant="additional">Создать</Button>
          <Button
            variant="text"
            icon={<Icon name="Edit" />}
            overrideFillIcon
            onClick={selectedRows.length !== 1 ? undefined : () => {
              alert(`Изменяем строку... ${selectedRows.map((row) => row.code || row._key).join(', ')}`)
            }}
            disabled={selectedRows.length !== 1}
          />
          <Button
            variant="text"
            icon={<Icon name="Delete" />}
            overrideFillIcon
            onClick={selectedRows.length === 0 ? undefined : () => {
              alert(`Удаляем строки... ${selectedRows.map((row) => row.code || row._key).join(', ')}`)
            }}
            disabled={selectedRows.length === 0}
          />
          <div style={{ height: 32, width: 1, backgroundColor: 'rgba(229, 233, 242, 1)' }} />
          <Button
            variant="text"
            icon={<Icon name="Filter" />}
            overrideFillIcon
          />
          <Button
            variant="text"
            icon={<Icon name="Settings" />}
            overrideFillIcon
          />
        </div>
      </div>
      <ListFormTable
        listFormData={formData}
        selectRow={selectRow}
        // setFieldProp={setFieldProp}
        setSortField={setSortField}
      />

      {/*<div>Выделенные строки:</div>*/}
      {/*{formData.rows*/}
      {/*  .filter(({ _selected }) => _selected)*/}
      {/*  .map((row) => (*/}
      {/*    <div key={row._key}>{JSON.stringify(row)},</div>*/}
      {/*  ))}*/}

      {/*<div>Поля:</div>*/}
      {/*{formData.fields.map((field) => (*/}
      {/*  <div key={field.code}>{JSON.stringify(field)},</div>*/}
      {/*))}*/}
    </div>
  );
};
