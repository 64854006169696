import React, { createContext, useEffect, useState } from 'react';
import { fetchMetadata, IMetadataItem } from '../servises/adm';
import { transformArrayToObject, uniqBy } from '../utils/functions';


export const MetaColumnFieldTypes = ['String', 'Number', 'Boolean', 'DateTime', 'Enum'];


export type MetaEnums = Record<
  string,
  { dict: string, values:  Record<string, IMetadataItem> }
>;
export type MetaFields = Record<string, IMetadataItem>;

interface MetadataContextValue {
  metaEnums: MetaEnums;
  metaDictionary: IMetadataItem[];
  metaListview: IMetadataItem[];
  metaFields: MetaFields;
  isLoadingMetadata: boolean;
}

export const MetadataContext = createContext<MetadataContextValue>({
  metaEnums: {},
  metaDictionary: [],
  metaListview: [],
  metaFields: {},
  isLoadingMetadata: false,
});
MetadataContext.displayName = 'MetadataContext';

type MetaDataProviderProps = { children: React.ReactNode };

export const MetaDataProvider = ({ children }: MetaDataProviderProps) => {
  const [metadata, setMetadata] = useState<IMetadataItem[]>([]);
  const [isLoadingMetadata, setIsLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetch = async () => {
      setIsLoading(true);
      fetchMetadata(signal)
        .then((data) => {
          setMetadata(data || []);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetch().then();

    return () => {
      abortController?.abort();
    };
  }, []);

  const metaEnums = transformArrayToObject(
    'dict',
    uniqBy(
      metadata.filter((d) => d.type === 'Enum'),
      (i) => i.dict
    ).map((enumDict) => {
      const values = transformArrayToObject('objectid', metadata.filter(
        (d) => d.type === '' && d.dict === enumDict.dict
      ));
      return { dict: enumDict.dict, values };
    })
  );
  const metaDictionary = metadata.filter((d) => d.type === 'Dictionary');
  const metaListview = metadata.filter((d) => d.type === 'Listview');
  const metaFields = transformArrayToObject(
    'objectid',
    metadata.filter((d) =>
      MetaColumnFieldTypes.includes(d.type)
    )
  );

  const contextValue = {
    metaEnums,
    metaDictionary,
    metaListview,
    metaFields,
    isLoadingMetadata,
  }
  console.log('contextValue', contextValue);

  return (
    <MetadataContext.Provider
      value={contextValue}
    >
      {children}
    </MetadataContext.Provider>
  );
};
