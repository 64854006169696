import { SchemaField } from '../types/common/list-form';

class FormFieldStorage {
  private storageKey = 'forms';

  // Получение всех данных из localStorage
  private getStorageData(): Record<string, SchemaField[]> {
    const data = localStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : {};
  }

  // Сохранение данных в localStorage
  private setStorageData(data: Record<string, SchemaField[]>): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  // Метод для получения объекта по ключу
  get(key: string): SchemaField[] | null {
    const data = this.getStorageData();
    return data[key] || null;
  }

  // Метод для добавления или перезаписи объекта
  add(key: string, field: SchemaField[]): void {
    const data = this.getStorageData();
    data[key] = field; // Перезаписываем, если ключ уже существует
    this.setStorageData(data);
  }

  // Метод для удаления объекта по ключу
  remove(key: string): void {
    const data = this.getStorageData();

    if (data[key]) {
      delete data[key];
      this.setStorageData(data);
    } else {
      console.warn(`Элемент с ключом "${key}" не найден для удаления.`);
    }
  }
}

export const formFieldStorage = new FormFieldStorage();

export const getFormFieldStorageKey = (service: string, name: string) =>
  `${service}.${name}`;

// // Пример использования
// const storage = new LocalStorageWrapper();
//
// // Добавляем новый элемент
// storage.add('user123', {
//   code: 'user123',
//   name: 'User',
//   type: 'text',
//   filter: ['active'],
//   sort: 'ASC',
//   pinned: null,
//   width: null,
// });
//
// // Обновляем элемент (только указанные свойства)
// storage.update('user123', {
//   name: 'Updated User',
//   sort: 'DESC',
// });
//
// // Удаляем элемент
// storage.remove('user123');
//
// // Получаем элемент
// const user = storage.get('user123');
// console.log(user);
