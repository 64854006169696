import axios from 'axios';
import { api } from '../utils/api';

export interface IMetadataItem {
  kind: number;
  objectid: string;
  localization: number;
  localname: string;
  type: string;
  service: string;
  dict: string;
}

export const fetchMetadata = async (
  signal: AbortSignal
): Promise<IMetadataItem[] | undefined> => {
  try {
    const data = (
      await api.get('/api/adm/metadata', {
        signal,
      })
    ).data as [];
    return data;
  } catch (err) {
    if (!axios.isCancel(err)) {
      throw err;
    }
  }
};
