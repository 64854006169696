import React, { useState } from 'react';
import { Button } from '../components/uikit/Button/Button';
import { Checkbox } from '../components/uikit/Checkbox/Checkbox';
import { ColorPicker } from '../components/uikit/ColorPicker/ColorPicker';
import { Icon } from '../components/uikit/Icon/Icon';
import { OptionValue, SelectField } from '../components/uikit/SelectField/SelectField';
import { TextField } from '../components/uikit/TextField/TextField';
import { Tooltip } from '../components/uikit/Tooltip/Tooltip';

import styles from './DevUiKitPage.module.css';


const options = [
  {value: 'VRN', name: 'Воронеж'},
  {value: 'MSK', name: 'Москва'},
  {value: 'SPB', name: 'Санкт-Петербург'},
  {value: 'EKB', name: 'Екатеринбург'},
]

export const DevUiKitPage = () => {
  const [text, setText] = useState('');

  const [selectValues, setSelectValues] = useState<OptionValue[]>([]);

  const [checked, setChecked] = useState(false);

  const [colorBGInputs, setColorBGInputs] = useState('#f5f5f5');

  return (
    <div style={{ padding: 20 }}>
      <div style={{ margin: '10px 0' }}>Элементы интерфейса</div>
      <div style={{ margin: '10px 0' }}>Кнопки</div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Normal</th>
            <th>Disabled</th>
            <th>Текст с иконкой</th>
            <th>Иконка</th>
            <th>Иконка Disabled</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Button text="Основная кнопка" />
            </td>
            <td>
              <Button text="Основная кнопка" disabled />
            </td>
            <td>
              <Button text="С иконкой" icon={<Icon name="User" />} overrideFillIcon />
            </td>
            <td>
              <Button icon={<Icon name="User" />} overrideFillIcon />
              <Button icon={<Icon name="User" />} overrideFillIcon rounded />
            </td>
            <td>
              <Button icon={<Icon name="User" />} overrideFillIcon disabled />
            </td>
          </tr>
          <tr>
            <td>
              <Button text="Вторичная кнопка" variant="secondary" />
            </td>
            <td>
              <Button text="Вторичная кнопка" variant="secondary" disabled />
            </td>
            <td>
              <Button text="С иконкой" variant="secondary" icon={<Icon name="User" />} overrideFillIcon />
            </td>
            <td>
              <Button variant="secondary" icon={<Icon name="User" />} overrideFillIcon />
              <Button variant="secondary" icon={<Icon name="User" />} overrideFillIcon rounded />
            </td>
            <td>
              <Button variant="secondary" icon={<Icon name="User" />} overrideFillIcon disabled />
            </td>
          </tr>
          <tr>
            <td>
              <Button text="Дополнительная" variant="additional" overrideFillIcon />
            </td>
            <td>
              <Button text="Дополнительная" variant="additional" overrideFillIcon disabled />
            </td>
            <td>
              <Button text="С иконкой" variant="additional" icon={<Icon name="User" />} overrideFillIcon />
            </td>
            <td>
              <Button variant="additional" icon={<Icon name="User" />} overrideFillIcon />
              <Button variant="additional" icon={<Icon name="User" />} overrideFillIcon rounded />
            </td>
            <td>
              <Button variant="additional" icon={<Icon name="User" />} overrideFillIcon disabled />
            </td>
          </tr>
          <tr>
            <td>
              <Button text="Невидимая" variant="text" />
            </td>
            <td>
              <Button text="Невидимая" variant="text" disabled />
            </td>
            <td>
              <Button text="С иконкой" variant="text" icon={<Icon name="User" />} overrideFillIcon />
            </td>
            <td>
              <Button variant="text" icon={<Icon name="User" />} overrideFillIcon />
              <Button variant="text" icon={<Icon name="User" />} overrideFillIcon rounded />
            </td>
            <td>
              <Button variant="text" icon={<Icon name="User" />} overrideFillIcon disabled />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ margin: '10px 0' }}>Тултип:</div>
      <div>
        над иконкой{' '}
        <Tooltip text="User">
          <Icon name="User" />
        </Tooltip>
      </div>
      <div>
        над div{' '}
        <Tooltip text="User">
          <div>
            {'<'}div{'>'} text
          </div>
        </Tooltip>
      </div>
      <div>
        над span{' '}
        <Tooltip text="User">
          <span>
            {'<'}span{'>'} text
          </span>
        </Tooltip>
      </div>
      <div>
        над span{' '}
        <Tooltip text="очень очень очень очень очень очень очень очень очень много текста">
          <span>очень много текста</span>
        </Tooltip>
      </div>
      <div>
        с delay 300{' '}
        <Tooltip text="User" delay={300}>
          <Icon name="User" />
        </Tooltip>
      </div>
      <br />
      <br />
      <Checkbox label="Лейбл" checked={checked} handleChange={setChecked} />
      <br />
      <Checkbox label="Лейбл дизайблд" checked={checked} handleChange={setChecked} disabled />
      <br />
      <br />
      <div style={{ margin: '10px 0' }}>Пример инпутов, значение: {text}</div>
      <span>Цвет фона. Выбрать: </span>
      <div
        onClick={() => setColorBGInputs('#f5f5f5')}
        style={{
          display: 'inline-block',
          position: 'relative',
          width: 30,
          height: 20,
          backgroundColor: '#f5f5f5',
          border: '1px black solid',
          cursor: 'pointer',
        }}
      ></div> {' '}
      <div
        onClick={() => setColorBGInputs('#ffffff')}
        style={{
          display: 'inline-block',
          position: 'relative',
          width: 30,
          height: 20,
          backgroundColor: '#ffffff',
          border: '1px black solid',
          cursor: 'pointer',
        }}
      ></div> {' '}
      или...
      <ColorPicker color={colorBGInputs} handleColorChange={setColorBGInputs} />

      <table>
        <tbody>
        <tr>
          <td>
            <div
              style={{
                width: 300,
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                padding: 8,
                backgroundColor: colorBGInputs,
              }}
            >
              <TextField
                label="Обязательный"
                placeholder="Введите значение"
                required
                value={text}
                handleChange={setText}
              />
              <TextField
                label="С ошибкой"
                placeholder="Введите значение"
                required
                error={'Ошибка!'}
                value={text}
                handleChange={setText}
              />
              <TextField
                label="С варнингом"
                placeholder="Введите значение"
                required
                warning={'Предупреждение!'}
                value={text}
                handleChange={setText}
              />
              <br />
              <br />
              <TextField
                placeholder="Без лейбла"
                value={text}
                handleChange={setText}
              />
              <TextField
                label="Disabled"
                placeholder="Disabled"
                disabled
                value={text}
                handleChange={setText}
              />
              <TextField
                label="Очищаемое"
                placeholder="Очищаемое"
                clearable
                value={text}
                handleChange={setText}
              />
              <TextField
                label="Очищаемое onChange"
                placeholder="Очищаемое onChange"
                clearable
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </td>
          <td>
            <div
              style={{
                width: 300,
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                padding: 8,
                backgroundColor: colorBGInputs,
              }}
            >
              <SelectField
                values={selectValues}
                options={options}
                handleChange={(values) => setSelectValues(values)}
              />
              <SelectField
                values={selectValues}
                options={options}
                handleChange={(values) => setSelectValues(values)}
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>



      <br />
      <br />
      <form>
        <label htmlFor="city-select">Ваш город</label>
        <select name="city" id="city-select" multiple >
          <option value="">-- Выберите город --</option>
          <option value="petersburg">Санкт-Петербург</option>
          <option value="samara">Самара</option>
          <option value="perm">Пермь</option>
          <option value="novosibirsk">Новосибирск</option>
        </select>
      </form>
      <form>
        <label htmlFor="city-select">Ваш город</label>
        <select name="city" id="city-select" multiple >
          <option value="">-- Выберите город --</option>
          <option value="petersburg">Санкт-Петербург</option>
          <option value="samara">Самара</option>
          <option value="perm">Пермь</option>
          <option value="novosibirsk">Новосибирск</option>
        </select>
      </form>

      {/*<TextField*/}
      {/*  label="Название"*/}
      {/*  placeholder="Введите значение"*/}
      {/*  error="Сообщение об ошибке"*/}
      {/*/>*/}
      {/*<TextField*/}
      {/*  label="Название"*/}
      {/*  placeholder="Введите значение"*/}
      {/*  warning="Предупреждающее сообщение"*/}
      {/*/>*/}
      {/*<TextField*/}
      {/*  label="Название"*/}
      {/*  placeholder="Значение"*/}
      {/*  disabled*/}
      {/*/>*/}
    </div>
  );
};
